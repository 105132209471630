import React from 'react';

import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
// import LinkButton from '../components/Buttons/Link';

// assets
import { FaPaperPlane } from 'react-icons/fa';

const WhoWeAre = () => {
  return (
    <Container className="flex-col">
      <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
        <div className="grid-container w-full">
          <div>
            <SectionHeading>
              All About <span className="fw-600 text-primary">HQ</span>
            </SectionHeading>

            <div className="mt-8 lg:mt-16">
              <div>
                <p className="fs-16">
                  HQ is a Product team for the Product teams. We collaborate and
                  work with early stage startups and help them design, build,
                  scale and iterate their products in the quickest possible time
                  frame.
                </p>
                <p className="fs-16 mt-10 leading-5">
                  We have deep expertise in services such as UX and UI Design,
                  Mobile App Development, Web App Development, Backend
                  Development, DevOps, 3rd Party API Integrations, Security, and
                  Compliances.
                </p>
                <p className="fs-16 mt-10 leading-5">
                  We aim for high-end designs and scalable solutions while
                  building products for the end-users.
                </p>
              </div>
            </div>
          </div>
          <div className="hidden lg:block">
            <div className="content">
              <div className="flex flex-col justify-center items-center lg:items-end fs-13">
                <span
                  className="text-center md:text-right leading-loose border-b border-red-500 pb-8 z-10"
                  style={{ width: 300 }}
                >
                  <div className="block md:hidden">
                    <span className="font-bold fs-16 text-center">
                      {' '}
                      HQ&apos;s Team
                    </span>
                  </div>
                  <div>Product Planning</div>
                  <div>Product Design & Development</div>
                  <div>DevOps, Security & Compliances</div>
                </span>
                <span
                  className="text-center md:text-right leading-loose border-b border-red-500 py-12 text-white fw-400 z-10"
                  style={{ width: 300 }}
                >
                  <div className="flex md:hidden">
                    <span className="font-bold fs-16 text-center mb-4 mx-auto">
                      {/* <FaPaperPlane>Hie HQ</FaPaperPlane> */}
                      HQ's Clients
                    </span>
                  </div>
                  <div>Get Tech & Product Expertise</div>
                  <div>Cobuild - Build Better, Faster</div>
                  <div>Mitigate Product Failure Risks</div>
                  <div>On-demand Product Support</div>
                </span>
                <span
                  className="text-center md:text-right leading-loose pt-8 z-10"
                  style={{ width: 300 }}
                >
                  <div className="block md:hidden">
                    <span className="font-bold fs-16 text-center">
                      HQ's Partners
                    </span>
                  </div>
                  <div>Product Experts</div>
                  <div>Domain Insights & Strategy</div>
                  <div>Expert Support</div>
                </span>

                <div className="relative" style={{ width: 300 }}>
                  <div className="hidden md:flex flex-col items-center justify-center z-0 circle--wrapper">
                    <span className="relative overflow-hidden circle">
                      <span className="font-bold fs-16 fw-600 absolute text-center text-black">
                        HQ's Team
                      </span>
                    </span>
                    <span className="relative circle">
                      <span className="font-bold fs-16 fw-600 absolute text-center text-black">
                        HQ's Partners
                      </span>
                    </span>
                    <span className="font-bold fs-16 absolute text-center z-10 text-black">
                      HQ's Clients
                      <span className="center-line border-t border-red-500" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-10">
            <LinkButton as="link">Read More</LinkButton>
          </div> */}
        </div>
      </span>
    </Container>
  );
};

export default WhoWeAre;
