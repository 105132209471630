import React, { useState } from 'react';
import { FiCheckCircle } from 'react-icons/fi';

import Slider from '../Slider';
// import LinkButton from '../Buttons/Link';
import Dropdown from '../Dropdown';

// assets
import PristeemThumbnailLG from '../../images/industry-wise-solution/lg/pristeem.png';
import FlexnestThumbnailLG from '../../images/industry-wise-solution/lg/flexnest.png';
import DiallThumbnailLG from '../../images/industry-wise-solution/lg/diall.png';
import FanclubThumbnailLG from '../../images/industry-wise-solution/lg/fanclub.png';
import SafegoldThumbnailLG from '../../images/industry-wise-solution/lg/safegold.png';
import MojaThumbnailLG from '../../images/industry-wise-solution/lg/moja.png';
import GoAuditsThumbnailLG from '../../images/industry-wise-solution/lg/go-audits.png';
import DebbieThumbnailLG from '../../images/industry-wise-solution/lg/debbie.png';
import PizzaImageEditorThumbnailLG from '../../images/industry-wise-solution/lg/pizza-image-editor.png';
import SparkWorksThumbnailLG from '../../images/industry-wise-solution/lg/spark-works.png';
import RemindThumbnailLG from '../../images/industry-wise-solution/lg/remind.png';
import I2IThumbnailLG from '../../images/industry-wise-solution/lg/i2i.png';
import DuntepThumbnailLG from '../../images/industry-wise-solution/lg/duntep.png';

// mobile screens
import PristeemThumbnailSM from '../../images/industry-wise-solution/sm/pristeem.png';
import FlexnestThumbnailSM from '../../images/industry-wise-solution/sm/flexnest.png';
import DiallThumbnailSM from '../../images/industry-wise-solution/sm/diall.png';
import FanclubThumbnailSM from '../../images/industry-wise-solution/sm/fanclub.png';
import SafegoldThumbnailSM from '../../images/industry-wise-solution/sm/safegold.png';
import MojaThumbnailSM from '../../images/industry-wise-solution/sm/moja.png';
import GoAuditsThumbnailSM from '../../images/industry-wise-solution/sm/go-audits.png';
import DebbieThumbnailSM from '../../images/industry-wise-solution/sm/debbie.png';
import PizzaImageEditorThumbnailSM from '../../images/industry-wise-solution/sm/pizza-image-editor.png';
import SparkWorksThumbnailSM from '../../images/industry-wise-solution/sm/spark-works.png';
import RemindThumbnailSM from '../../images/industry-wise-solution/sm/remind.png';
import I2IThumbnailSM from '../../images/industry-wise-solution/sm/i2i.png';
import DuntepThumbnailSM from '../../images/industry-wise-solution/sm/duntep.png';

const tabs = [
  {
    key: 0,
    title: 'Fintech & Financial',
    description: null,
    checkList: [
      'Wallet and Banking Applications',
      'Global Payment Platforms',
      'Taxation, Analytics, Fintech SaaS Applications',
      'Stock Brokerage and Insurance Products',
      'Vaults and E-settlements',
    ],
    thumbnail: [SafegoldThumbnailLG, DebbieThumbnailLG, MojaThumbnailLG],
    thumbnailSM: [SafegoldThumbnailSM, DebbieThumbnailSM, MojaThumbnailSM],
  },
  {
    key: 1,
    title: 'Social & Community',
    description: null,
    checkList: [
      'Social Media Applications',
      'Community Applications',
      'NFT Applications',
    ],
    thumbnail: [DiallThumbnailLG, FanclubThumbnailLG, FlexnestThumbnailLG],
    thumbnailSM: [DiallThumbnailSM, FanclubThumbnailSM, FlexnestThumbnailSM],
  },
  {
    key: 2,
    title: 'Fitness & Healthcare',
    description: null,
    checkList: [
      'Fintess Applications',
      'Mental Healthcare Applications',
      'Primary Healthcare Applications',
    ],
    thumbnail: [RemindThumbnailLG, FlexnestThumbnailLG],
    thumbnailSM: [RemindThumbnailSM, FlexnestThumbnailSM],
  },
  {
    key: 3,
    title: 'SAAS Applications',
    description: null,
    checkList: [
      'Fintech SaaS',
      'Enterprise Applications',
      'PM and Automation Tools',
      'Editing Applications',
    ],
    thumbnail: [GoAuditsThumbnailLG, PizzaImageEditorThumbnailLG],
    thumbnailSM: [GoAuditsThumbnailSM, PizzaImageEditorThumbnailSM],
  },
  {
    key: 4,
    title: 'BLE and IoT',
    description: null,
    checkList: [
      'BLE Applications',
      'NFC & RFID Applications',
      'EV Applications',
    ],
    thumbnail: [PristeemThumbnailLG, SparkWorksThumbnailLG],
    thumbnailSM: [PristeemThumbnailSM, SparkWorksThumbnailSM],
  },
  {
    key: 5,
    title: 'Other Domains',
    description: null,
    checkList: [
      'E-commerce',
      'Gaming Applications',
      'Blockchain Applications',
      'AI and ML Applications',
    ],
    thumbnail: [I2IThumbnailLG, DuntepThumbnailLG],
    thumbnailSM: [I2IThumbnailSM, DuntepThumbnailSM],
  },
];

const sliderOptions = {
  slidesToShow: 1,
  arrows: false,
  draggable: false,
  adaptiveHeight: false,
  swipeToSlide: false,
  swipe: false,
  infinite: false,
};

const SolutionTabs = () => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div>
      <ul className="hidden lg:inline-flex tabs">
        {tabs.map((tab, i) => {
          return (
            <li
              key={`tab-${i}`}
              className={`tab ${activeTab.key === i ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab.title}
            </li>
          );
        })}
      </ul>
      <Dropdown
        className="block lg:hidden mt-12 lg:mt-0"
        options={tabs}
        selectedIndex={activeTab.key}
        callback={data => setActiveTab(data)}
      />
      <div className="tab-content grid mt-12 lg:mt-16">
        <div className="gallary ml-auto lg:ml-6 mr-auto lg:mr-0">
          <div className="relative">
            <Slider
              id="solutions__primary-image"
              settings={sliderOptions}
              goto={activeTab.key}
            >
              {tabs.map((tab, i) => {
                return (
                  <span key={`primary-image-${i}`}>
                    <div className="hidden lg:block card">
                      <img src={tab.thumbnail[0]} alt={tab.title} />
                    </div>
                    <div className="block lg:hidden card">
                      <img src={tab.thumbnailSM[0]} alt={tab.title} />
                    </div>
                  </span>
                );
              })}
            </Slider>
            <div className="secondary">
              <Slider
                id="solutions__secondary-image1"
                settings={sliderOptions}
                goto={activeTab.key}
              >
                {tabs.map((tab, i) => {
                  return (
                    <span key={`secondary-image1-${i}`}>
                      <div className="hidden lg:block card">
                        <img src={tab.thumbnail[1]} alt={tab.title} />
                      </div>
                      <div className="block lg:hidden card">
                        <img src={tab.thumbnailSM[1]} alt={tab.title} />
                      </div>
                    </span>
                  );
                })}
              </Slider>
            </div>
          </div>
          <span className="overlay"></span>
        </div>
        <div className="content w-full overflow-hidden mt-4 lg:mt-8">
          <Slider
            id="solutions__content"
            settings={{
              ...sliderOptions,
              fade: true,
            }}
            goto={activeTab.key}
          >
            {tabs.map((item, i) => {
              return (
                <div
                  className="w-full slide-content"
                  key={`solutions__content-${i}`}
                >
                  <h6 className="fs-28 fw-700">{item.title}</h6>
                  {item.description && (
                    <p className="text-gray-2 mt-4" style={{ maxWidth: 390 }}>
                      {item.description}
                    </p>
                  )}
                  <ul className="fs-16 fw-700 mt-8">
                    {item.checkList.map((checkItem, idx) => (
                      <li
                        key={`check-item-${idx}`}
                        className="flex items-center mb-4"
                      >
                        <FiCheckCircle
                          size={24}
                          strokeWidth={3}
                          className="mr-3"
                        />{' '}
                        {checkItem}
                      </li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default SolutionTabs;
