import React from 'react';
import { Link } from 'gatsby';

import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';

const BusinessModel = () => {
  return (
    <Container className="flex-col">
      <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
        <SectionHeading theme="dark">
          {/* Business <span className="fw-800">Model</span> */}
          Cobuilding Engagements To Support Your Product Journey
        </SectionHeading>

        <div className="w-full mt-16 lg:mt-24 mb-16">
          <div className="grid lg:grid-cols-2 gap-10 lg:gap-24 text-black">
            <span>
              <h4 className="fs-32 fw-300 ff-primary m-0">
                Fixed Scope Engagement
              </h4>
              <p className="mt-8">
                We take care of end-to-end product in this engagement. This
                engagement is ideal when you are crystal clear on your
                requirements. We estimate and share a fix cost, delivery
                milestones, and payment milestones so you can plan your budget
                and launch of the product accordingly.
              </p>
            </span>
            <span>
              <h4 className="fs-32 fw-300 ff-primary m-0">Hourly Engagement</h4>
              <p className="mt-8">
                This engagement is preferred by teams looking to pivot around
                their products quickly. In this engagement, you only pay for
                time and resources spent on your project. Payments are billed
                monthly/bi-montly as per the logged efforts marked by our team.
              </p>
            </span>
            <span>
              <h4 className="fs-32 fw-300 ff-primary m-0">Dedicated Team</h4>
              <p className="mt-8">
                This engagement works well when you have an existing team and
                have plans to scale your product quickly. A dedicated
                team/individual resources are provided on a monthly basis and
                billings are made accordingly.
              </p>
            </span>
            <span>
              <div className="cta mx-auto lg:mx-0">
                <div className="py-8 lg:py-10 px-8 lg:px-10">
                  <span className="ff-primary text-white fs-32 fs-xl-36 fw-300">
                    Looking for a more flexible engagement?
                  </span>
                  <div className="mt-10 flex items-center justify-start">
                    {/* <Link
                      className="btn btn-rounded border border-white fs-14 fs-xl-16 fw-400 text-center block"
                      to="/contact-us"
                    >
                      Explore Services
                    </Link> */}

                    <Link
                      className="btn btn-rounded btn-white border border-white fs-14 fs-xl-16 fw-400 text-center block"
                      to="/contact-us"
                    >
                      Inquire Now
                    </Link>
                  </div>
                </div>
              </div>
            </span>
          </div>
        </div>
      </span>
    </Container>
  );
};

export default BusinessModel;
