import React from 'react';
import PropTypes from 'prop-types';
import SlideToggle from 'react-slide-toggle';
import { FiChevronDown } from 'react-icons/fi';

const Accordion = ({ title, children, theme }) => {
  return (
    <SlideToggle
      collapsed
      render={({ toggle, setCollapsibleElement, toggleState }) => {
        return (
          <div className={`accordion-item state-${toggleState} ${theme}`}>
            <div
              className="accordion-title ff-primary flex items-center justify-start fw-400"
              onClick={toggle}
            >
              <div className="fw-300">{title}</div>
              <div className="ml-auto">
                <FiChevronDown />
              </div>
            </div>
            <div className="accordion-content" ref={setCollapsibleElement}>
              {children}
            </div>
          </div>
        );
      }}
    />
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  theme: PropTypes.string,
};

Accordion.defaultProps = {
  theme: 'dark',
};

export default Accordion;
