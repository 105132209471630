import React from 'react';
import Container from '../components/Container';

const Motto = () => {
  return (
    <Container
      className="flex-col justify-center items-center text-white"
      screenHeight
    >
      <div className="highlighted-container flex flex-col items-center">
        <span className="bordered-text fs-40 fs-lg-72">BUILD</span>
        <span className="fs-40 fs-lg-72 fw-800">
          <span className="text-primary">2 X</span> BETTER
        </span>
        <span className="bordered-text fs-40 fs-lg-72">
          <span className="text-primary">2 X</span> FASTER
        </span>
        <span className="ff-primary fs-32 fs-lg-46 fw-300 text-center mt-12 lg:mt-20">
          WITH OUR <span className="text-primary fw-400">STARTUP CENTRIC</span>{' '}
          SERVICES
        </span>
      </div>
      <div className="ff-primary flex items-center justify-center mt-10">
        <p className="text-center fs-16" style={{ maxWidth: '700px' }}>
          Build high-end designs and scalable products right from the day one.
          Cobuild with our team in order to ensure the best product for the
          end-users.
        </p>
      </div>
    </Container>
  );
};

export default Motto;
