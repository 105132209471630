import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { FiChevronDown } from 'react-icons/fi';

const Dropdown = ({ id, className, options, selectedIndex, callback }) => {
  const dropdownId = `dropdown__${id}`;
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleSelected = (data, index) => {
    setSelected(data);
    setOpen(false);
    callback(data, index);
  };

  useEffect(() => {
    setSelected(options[selectedIndex] ? options[selectedIndex] : null);
  }, []);

  useEffect(() => {
    setSelected(options[selectedIndex] ? options[selectedIndex] : null);
  }, [selectedIndex]);

  return (
    <div
      id={dropdownId}
      className={`dropdown__wrapper ${className} ${open ? 'open' : 'closed'}`}
    >
      <label
        className="flex items-center justify-start"
        onClick={() => setOpen(!open)}
      >
        <span>{selected ? selected.title : ''}</span>
        <FiChevronDown size={28} className="ml-auto" />
      </label>
      <div className="options">
        <ul>
          {options.map((item, i) => (
            <li key={`option-${i}`} onClick={() => handleSelected(item, i)}>
              {item.title}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  selectedIndex: PropTypes.number,
  callback: PropTypes.func,
};

Dropdown.defaultProps = {
  id: `${parseInt(Math.random() * 100000000)}`,
  className: '',
  options: [],
  selectedIndex: 0,
  callback: () => null,
};

export default Dropdown;
