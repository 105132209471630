import React from 'react';

import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import SolutionTabs from '../components/SolutionTabs';

const OurSolutions = () => {
  return (
    <Container className="flex-col">
      <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
        <SectionHeading className="fw-300">
          Our <span className="fw-400">Industry Specific Solutions</span>
        </SectionHeading>

        <div className="w-full lg:mt-16 mb-8">
          <SolutionTabs />
        </div>
      </span>
    </Container>
  );
};

export default OurSolutions;
