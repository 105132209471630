import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
// import PropTypes from "prop-types"

import Container from '../components/Container';
// import SectionHeading from '../components/SectionHeading';
// import Slider from '../components/Slider';

// const sliderOptions = {
//   dots: false,
//   arrows: true,
//   autoPlay: false,
//   draggable: true,
//   infinite: true,
//   speed: 500,
//   slidesToShow: 3,
//   slidesToScroll: 1,
//   adaptiveHeight: true,
//   swipeToSlide: true,
//   swipe: true,
//   centerMode: false,
//   responsive: [
//     {
//       breakpoint: 1200,
//       settings: {
//         slidesToShow: 2.5,
//       },
//     },
//     {
//       breakpoint: 992,
//       settings: {
//         slidesToShow: 2,
//       },
//     },
//     {
//       breakpoint: 768,
//       settings: {
//         slidesToShow: 1.5,
//         arrows: false,
//         centerMode: true,
//         centerPadding: '20%',
//       },
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 1.25,
//         arrows: false,
//         centerMode: true,
//         centerPadding: '20%',
//       },
//     },
//   ],
// };

const Hero = () => {
  return (
    <Container
      className="flex-col"
      backgroundConfig={{
        type: 'video',
        url: 'https://hiehq.s3.us-east-2.amazonaws.com/assets/home-hero.mp4',
        style: {
          height: 0,
          top: '0%',
          paddingTop: '56.25%',
          maxWidth: 720,
        },
        className: 'hidden lg:block',
      }}
    >
      <span className="flex-1 flex items-center justify-center w-full">
        <div className="relative flex-1 flex flex-col items-start justify-center">
          <h1
            className="ff-tertiary fs-46 fs-lg-48 fw-500 m-0"
            style={{ maxWidth: '460px' }}
          >
            <span className="text-primary fw-700">Cobuild</span> Products with
            Higher Chances of{' '}
            <span className="text-primary fw-700">Success!</span>
          </h1>
          <p className="mt-10 fs-16 z-50" style={{ maxWidth: '500px' }}>
            Join hands with our product team to bring a fresh perspective to
            your idea and take it to the next level.
          </p>
          <div className="mt-10">
            <Link
              className="btn btn-primary fs-16 fw-400 block"
              to="/contact-us"
            >
              Let&apos;s Build Your Product Together
            </Link>
          </div>
        </div>
        {/* <div className="flex-1 hidden lg:flex items-center justify-center">
          <StaticImage
            src="../images/hero-primary.inline.svg"
            alt="HieHQ"
            placeholder="blurred"
            layout="fixed"
            width={300}
            height={300}
          />
        </div> */}
      </span>
      {/* <span
        id="hero--bst"
        className="flex-1 flex items-center justify-center w-full mt-16 md:mt-24 lg:mt-48"
      >
        <div className="flex-1 flex items-center justify-center w-full">
          <span className="hidden md:block">
            <StaticImage
              src="../images/hero-secondary.inline.svg"
              alt="HieHQ"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              breakpoints={[768, 992, 1200, 1400]}
            />
          </span>
          <span className="block md:hidden">
            <StaticImage
              src="../images/hero-secondary-mbl.inline.svg"
              alt="HieHQ"
              placeholder="blurred"
              layout="constrained"
              quality={100}
              breakpoints={[768, 992, 1200, 1400]}
            />
          </span>
        </div>
      </span> */}
      {/* <span
        id="hero--success-stories"
        className="flex-1 hidden xl:flex flex-col items-start justify-start w-full mt-16 lg:mt-2"
      >
        <SectionHeading subTitle="Projects we helped our clients to achieve success.">
          <span className="fw-800">Success</span> Stories
        </SectionHeading>

        <div className="w-full mt-28">
          <Slider id="slider__success-story" settings={{ ...sliderOptions }}>
            {[...Array(5)].map((_, i) => {
              return (
                <span key={`success-story-${i}`}>
                  <div className="card">
                    <div className="h-full flex items-end justify-center px-4 py-4">
                      <div className="card-title py-5 px-6">
                        <div className="ff-primary fs-20 text-white fw-600">
                          Project Name
                        </div>
                        <div className="fs-13 text-white mt-1">
                          Mobile App Development
                        </div>
                      </div>
                    </div>
                    <StaticImage
                      src="../images/stock-images/02.jpg"
                      alt="HieHQ"
                      layout="constrained"
                    />
                  </div>
                </span>
              );
            })}
          </Slider>
        </div>
      </span> */}
    </Container>
  );
};

export default Hero;
