import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import Slider from '../components/Slider';
// import LinkButton from '../components/Buttons/Link';

const sliderOptions = {
  infinite: true,
  dots: true,
  centerMode: true,
  centerPadding: '40px',
  autoPlay: false,
  draggable: false,
  speed: 500,
  slidesToShow: 2.1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  swipeToSlide: true,
  swipe: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2.1,
        arrows: true,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2.1,
        arrows: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        arrows: false,
        centerPadding: '0',
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        centerMode: true,
        arrows: false,
        centerPadding: '0',
      },
    },
  ],
};

const Reviews = () => {
  const data = useStaticQuery(graphql`
    query {
      RaviMekala: file(relativePath: { eq: "reviews/ravi-mekala.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      JulianOsborne: file(relativePath: { eq: "reviews/julian-osborne.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      AlexSaddler: file(relativePath: { eq: "reviews/alex-saddler.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      AnnaBofa: file(relativePath: { eq: "reviews/anna-bofa.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      SukhrajSingh: file(relativePath: { eq: "reviews/sukhraj-singh.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      JonahSalita: file(relativePath: { eq: "reviews/jonah-salita.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      TahaSajid: file(relativePath: { eq: "reviews/taha-sajid.jpeg" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  // data
  const reviews = [
    {
      picture: data.RaviMekala.childImageSharp.gatsbyImageData,
      name: 'Ravi Mekala, Co-Founder, CTO at GoAudits',
      comment:
        "It was fantastic to work together with the Hie HQ team. The team's understanding of my requirements and experience was exceptional. Hie HQ team did an incredible job on my 2 projects, making timely deliveries and helping my clients. Everyone assigned to my projects was a top-notch, high-skilled, experienced, and helpful professional with a positive attitude. I would highly recommend this team to anyone",
    },
    {
      picture: data.JulianOsborne.childImageSharp.gatsbyImageData,
      name: 'Julian Osborne, Co-founder, Pelt8 GMBh',
      comment:
        'Great support by the Hie HQ Team on the frontend development of our prototype. Will work together with them again!',
    },
    {
      picture: data.AnnaBofa.childImageSharp.gatsbyImageData,
      name: 'Anna Boffa, Founder- Stealth',
      comment:
        'I had a great experience working with the Hie HQ team. They provide excellent work and service. They are a well oiled machine. I would definitely use them again!',
    },
    {
      picture: data.SukhrajSingh.childImageSharp.gatsbyImageData,
      name: 'Sukhraj Singh, Founder- Wurk',
      comment:
        'Hie HQ is a really good team that is excelling in design and development. Their business owners came across to me as leaders who really know how to lead a team and I have learnt a lot from them. Good luck with their future endeavours.',
    },
    {
      picture: data.JonahSalita.childImageSharp.gatsbyImageData,
      name: 'Jonah Salita, Co-founder, Diall',
      comment:
        "Thanks to the entire Hie HQ team for making Diall possible and supporting us along the way. We can't wait to keep growing with you guys as a key part of our team!",
    },
    {
      picture: data.TahaSajid.childImageSharp.gatsbyImageData,
      name: 'Taha Sajid, Solutions Architect-5g, Fintech Advisor',
      comment:
        "I have worked with Hie HQ in building the UX design for one of my Blockchain project - I found the team very professional, the quality standards were met in each of the project's phases. So, if any of you, who are looking for a top-notch UX/UI design for your Unicorn startup application, get in touch with them! Cheers",
    },
    {
      picture: data.AlexSaddler.childImageSharp.gatsbyImageData,
      name: 'Alex Saddler, Founder, FanClub LLC',
      comment:
        "I've had the pleasure of working with Hie HQ to develop and design a mobile application. They have a great, knowledgeable, and effective team that works hard to communicate the progress being done on the app. Hie HQ team is very reliable and easy to work with. They have excellent product development managers, and do a great job of keeping in mind all the stakeholders' values involved in a project",
    },
  ];

  return (
    <Container className="flex-col">
      <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
        <SectionHeading className="flex items-center justify-start w-full fw-300">
          <span className="fw-400">Reviews</span>
        </SectionHeading>

        <div className="w-full lg:mt-10 mb-16">
          <Slider id="slider__reviews" settings={sliderOptions}>
            {reviews.map(({ picture, name, comment }, i) => {
              return (
                <div key={`review-${i}`} className="card pt-24 mb-8">
                  <div className="relative pt-4 px-4 lg:px-6">
                    <span className="review__picture">
                      <GatsbyImage
                        image={picture}
                        alt={name}
                        className="w-[100%] h-[100%]"
                      />
                    </span>
                    <h4 className="reviewer text-white fs-16 fs-lg-24 ml-28 lg:ml-32 fw-700">
                      {name}
                    </h4>
                    <p
                      className="fw-400 fs-14 fs-lg-16 text-white leading-7 mt-8"
                      style={{ maxWidth: 800 }}
                    >
                      “{comment}”
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </span>
    </Container>
  );
};

export default Reviews;
