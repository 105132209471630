import React from 'react';
import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import Accordion from '../components/Accordion';
import { FaFigma, FaReact } from 'react-icons/fa';
import {
  SiAdobexd,
  SiMiro,
  SiIos,
  SiAndroid,
  SiReacttable,
  SiFlutter,
  SiNodedotjs,
  SiPython,
  SiJava,
  SiMongodb,
  SiAmazonaws,
  SiGooglecloud,
  SiMicrosoftazure,
  SiDigitalocean,
  SiMysql,
  SiAngular,
  SiJavascript,
  SiTestinglibrary,
  SiSpringsecurity,
  SiShopify,
  SiSquarespace,
  SiWebflow,
  SiPostgresql,
} from 'react-icons/si';

const accordions = [
  {
    title: 'Experience Design',
    content: [
      'UX Design',
      'Branding & UI Design',
      'Prototyping',
      'Graphics Design',
    ],
    icons: [<FaFigma key={1} />, <SiAdobexd key={2} />, <SiMiro key={3} />],
  },
  {
    title: 'Mobile App Development',
    content: [
      'Native iPhone App Development',
      'Native Android App Development',
      'React Native App Development',
      'Flutter App Development',
    ],
    icons: [
      <SiIos key={1} />,
      <SiAndroid key={2} />,
      <SiReacttable key={3} />,
      <SiFlutter key={4} />,
    ],
  },
  {
    title: 'Backend Development',
    content: [
      'Solutions Architect',
      'Database Management',
      'Server and Server-less Development',
      '3rd Party API Integrations',
    ],
    icons: [
      <SiNodedotjs key={1} />,
      <SiPython key={2} />,
      <SiJava key={3} />,
      <SiMongodb key={4} />,
      <SiMysql key={5} />,
      <SiPostgresql key={6} />,
    ],
  },
  {
    title: 'DevOps',
    content: [
      'DevOPs Consulting',
      'DevOps Automation',
      'Integration & Deployment',
      'Infrastructure Management',
    ],
    icons: [
      <SiAmazonaws key={1} />,
      <SiGooglecloud key={2} />,
      <SiMicrosoftazure key={3} />,
      <SiDigitalocean key={4} />,
    ],
  },
  {
    title: 'Web Development',
    content: [
      'Web App Development',
      'Custom Website Development',
      'E-commerce Development',
      'No-code Development',
    ],
    icons: [
      <FaReact key={1} />,
      <SiAngular key={2} />,
      <SiJavascript key={3} />,
      <SiShopify key={4} />,
      <SiSquarespace key={5} />,
      <SiWebflow key={6} />,
    ],
  },
  {
    title: 'Testing, Security & Compliances',
    content: [
      'QA - Manual & Automated',
      'Banking & Non-banking Security Encryption',
      'Fintech, Healthcare and Data compliances',
    ],
    icons: [<SiTestinglibrary key={1} />, <SiSpringsecurity key={2} />],
  },
];

const OurServices = () => {
  return (
    <Container className="flex-col">
      <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
        <SectionHeading theme="dark">
          HQ’s Key <span className="fw-400">Services</span>
        </SectionHeading>

        <div className="w-full mt-16">
          {accordions.map((item, i) => {
            return (
              <div key={`accordion-${i}`} className="mb-8">
                <Accordion title={item.title}>
                  <div className="pt-8 pl-8">
                    {item.content.map((obj, idx) => (
                      <li
                        key={'content-' + idx}
                        className="fs-16 leading-6"
                        style={{ color: '#868686', maxWidth: 650 }}
                      >
                        {obj}
                      </li>
                    ))}

                    <div className="mt-16">
                      <ul className="flex flex-wrap items-center justify-start tech-stacks fs-40">
                        {item.icons.map((icon, id) => (
                          <li className="mb-8" key={'icon-' + id}>
                            {icon}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </Accordion>
              </div>
            );
          })}
        </div>
      </span>
    </Container>
  );
};

export default OurServices;
