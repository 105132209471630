import * as React from 'react';
import PropTypes from 'prop-types';

// components
import Layout from '../components/Layout';
import Seo from '../components/SEO';
import Section from '../components/Section';
import Hero from '../sections/Hero';
import Motto from '../sections/Motto';
import WhoWeAre from '../sections/WhoWeAre';
// import Technologies from '../sections/Technologies';
import OurServices from '../sections/OurServices';
import OurWork from '../sections/OurWork';
import OurSolutions from '../sections/OurSolutions';
import BusinessModel from '../sections/BusinessModel';
import Reviews from '../sections/Reviews';
// import NewsBlogs from '../sections/NewsBlogs';

const IndexPage = ({ location }) => (
  <Layout pageId="home" location={location} footer={true}>
    <Seo title="Home" />
    <Section id="hero">
      <Hero />
    </Section>

    <Section id="motto" overflow="visible">
      <Motto />
    </Section>

    <Section id="work" backgroundColor="#FFFFFF">
      <OurWork />
    </Section>

    {/* <Section id="technologies" backgroundColor="#FFFFFF">
      <Technologies />
    </Section> */}

    <Section id="who-we-are">
      <WhoWeAre />
    </Section>

    <Section id="services" backgroundColor="#FFFFFF">
      <OurServices />
    </Section>

    <Section id="solutions">
      <OurSolutions />
    </Section>

    <Section id="business-model" backgroundColor="#FFFFFF">
      <BusinessModel />
    </Section>

    <Section id="reviews">
      <Reviews />
    </Section>

    {/* <Section id="news-blogs">
      <NewsBlogs />
    </Section> */}
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object,
};

export default IndexPage;
