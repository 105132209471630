import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Container from '../components/Container';
import SectionHeading from '../components/SectionHeading';
import LinkButton from '../components/Buttons/Link';

import FlexnestGif from '../images/home-page/flexnest.gif';
import FlexnestThumbnail from '../images/home-page/flexnest.svg';

const OurWork = () => {
  const data = useStaticQuery(graphql`
    query {
      phoneBg: file(relativePath: { eq: "home-page/our-work/phone-bg.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      pristeemCardBg: file(
        relativePath: { eq: "home-page/our-work/pristeem/pristeem-card-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemLogo: file(
        relativePath: { eq: "home-page/our-work/pristeem/pristeem-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenOne: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenTwo: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenThree: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-3.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenFour: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-4.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenFive: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-5.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenSix: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-5.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenSeven: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-7.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenEighth: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-8.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      pristeemScreenNine: file(
        relativePath: {
          eq: "home-page/our-work/pristeem/pristeem-screen-9.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      flexnestCardBg: file(
        relativePath: { eq: "home-page/our-work/flexnest/flexnest-card-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestLogo: file(
        relativePath: { eq: "home-page/our-work/flexnest/flexnest-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenOne: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-1.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenTwo: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-2.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenThree: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-3.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenFour: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-4.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenFive: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-5.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenSix: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-5.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenSeven: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-7.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      flexnestScreenEighth: file(
        relativePath: {
          eq: "home-page/our-work/flexnest/flexnest-screen-8.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      diallCardBg: file(
        relativePath: { eq: "home-page/our-work/diall/diall-card-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallLogo: file(
        relativePath: { eq: "home-page/our-work/diall/diall-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallScreenOne: file(
        relativePath: { eq: "home-page/our-work/diall/diall-screen-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallScreenTwo: file(
        relativePath: { eq: "home-page/our-work/diall/diall-screen-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallScreenThree: file(
        relativePath: { eq: "home-page/our-work/diall/diall-screen-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallScreenFour: file(
        relativePath: { eq: "home-page/our-work/diall/diall-screen-4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallScreenFive: file(
        relativePath: { eq: "home-page/our-work/diall/diall-screen-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      diallScreenSix: file(
        relativePath: { eq: "home-page/our-work/diall/diall-screen-6.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }

      fanclubCardBg: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-card-bg.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fanclubLogo: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-logo.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fanclubScreenOne: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-screen-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fanclubScreenTwo: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-screen-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fanclubScreenThree: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-screen-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fanclubScreenFour: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-screen-4.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      fanclubScreenFive: file(
        relativePath: { eq: "home-page/our-work/fanclub/fanclub-screen-5.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
  `);

  const products = [
    {
      name: 'Pristeem',
      logo: data.pristeemLogo.childImageSharp.gatsbyImageData,
      bgCard: data.pristeemCardBg.childImageSharp.gatsbyImageData,
      description: 'Clean, wrinkle and odour free clothes in minutes',
      screens: [
        data.pristeemScreenOne.childImageSharp.gatsbyImageData,
        data.pristeemScreenTwo.childImageSharp.gatsbyImageData,
        data.pristeemScreenThree.childImageSharp.gatsbyImageData,
        data.pristeemScreenFour.childImageSharp.gatsbyImageData,
        data.pristeemScreenFive.childImageSharp.gatsbyImageData,
        data.pristeemScreenSix.childImageSharp.gatsbyImageData,
        data.pristeemScreenSeven.childImageSharp.gatsbyImageData,
        data.pristeemScreenEighth.childImageSharp.gatsbyImageData,
        data.pristeemScreenNine.childImageSharp.gatsbyImageData,
      ],
    },
    {
      name: 'Flexnest',
      logo: data.flexnestLogo.childImageSharp.gatsbyImageData,
      bgCard: data.flexnestCardBg.childImageSharp.gatsbyImageData,
      description: 'Home Workout Fitness Platform',
      screens: [
        data.flexnestScreenOne.childImageSharp.gatsbyImageData,
        data.flexnestScreenTwo.childImageSharp.gatsbyImageData,
        data.flexnestScreenThree.childImageSharp.gatsbyImageData,
        data.flexnestScreenFour.childImageSharp.gatsbyImageData,
        data.flexnestScreenFive.childImageSharp.gatsbyImageData,
        data.flexnestScreenSix.childImageSharp.gatsbyImageData,
        data.flexnestScreenSeven.childImageSharp.gatsbyImageData,
        data.flexnestScreenEighth.childImageSharp.gatsbyImageData,
      ],
    },
    {
      name: 'Diall',
      logo: data.diallLogo.childImageSharp.gatsbyImageData,
      bgCard: data.diallCardBg.childImageSharp.gatsbyImageData,
      description: 'Making mental health approachable',
      screens: [
        data.diallScreenOne.childImageSharp.gatsbyImageData,
        data.diallScreenTwo.childImageSharp.gatsbyImageData,
        data.diallScreenThree.childImageSharp.gatsbyImageData,
        data.diallScreenFour.childImageSharp.gatsbyImageData,
        data.diallScreenFive.childImageSharp.gatsbyImageData,
        data.diallScreenSix.childImageSharp.gatsbyImageData,
      ],
    },
    {
      name: 'Fanclub',
      logo: data.fanclubLogo.childImageSharp.gatsbyImageData,
      bgCard: data.fanclubCardBg.childImageSharp.gatsbyImageData,
      description: 'A brand new social media experience',
      screens: [
        data.fanclubScreenOne.childImageSharp.gatsbyImageData,
        data.fanclubScreenTwo.childImageSharp.gatsbyImageData,
        data.fanclubScreenThree.childImageSharp.gatsbyImageData,
        data.fanclubScreenFour.childImageSharp.gatsbyImageData,
        data.fanclubScreenFive.childImageSharp.gatsbyImageData,
      ],
    },
  ];

  return (
    <Container className="flex-col">
      <span className="flex-1 flex flex-col items-start justify-start w-full mt-2">
        <SectionHeading theme="dark" className="fw-300">
          Our <span className="fw-400">Work</span>
        </SectionHeading>

        <div className="w-full mt-16 lg:mt-32">
          <div className="grid lg:grid-cols-2 gap-y-[50px] md:gap-x-[70px] md:gap-y-[100px] xl:gap-x-[100px]">
            {products.map(product => (
              <div
                key={product.name}
                className={`animated-card ${product.name
                  .replaceAll(' ', '')
                  .toLowerCase()}`}
              >
                <div className="card__bg">
                  <GatsbyImage image={product.bgCard} alt={product.name} />
                </div>
                <div className="card__info">
                  <div className="flex items-center">
                    <span className="card__info-logo">
                      <GatsbyImage image={product.logo} alt={product.name} />
                    </span>
                  </div>
                  <div className="card__info-text">{product.description}</div>
                </div>
                <div className="card__screens">
                  <div className="card__screens-container">
                    <div className="card__screens-container__bg">
                      <GatsbyImage
                        image={data.phoneBg.childImageSharp.gatsbyImageData}
                        alt={product.name}
                      />
                    </div>
                    <div
                      className={`card__screens-container__pics ${product.name
                        .replaceAll(' ', '')
                        .toLowerCase()}`}
                    >
                      {product.screens.reverse().map((image, index) => (
                        <div
                          key={index}
                          className="card__screens-container__pic"
                        >
                          <GatsbyImage image={image} alt={product.name} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="card__label">
                  <span className="card__label-title">{product.name}</span>
                  <span className="card__label-text">
                    UX/UI Design & Development
                  </span>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-8 mb-8 flex flex-col lg:flex-row lg:items-center lg:justify-start">
            <span>
              <a
                className="inline-block mr-8 lg:mr-16"
                href="https://dribbble.com/hiehq"
                target="_blank"
                rel="external noreferrer"
              >
                <span className="dribbble-logo ff-secondary bordered-text fw-800 fs-48">
                  Dribbble
                </span>
              </a>
              <a
                className="inline-block mt-4 lg:mt-0"
                href="https://www.behance.net/hiestudiohq"
                target="_blank"
                rel="external noreferrer"
              >
                <span className="behance-logo ff-secondary bordered-text fw-800 fs-48">
                  Behance
                </span>
              </a>
            </span>
            <span className="lg:ml-auto mt-20 lg:mt-0">
              <LinkButton as="link" to="/portfolio">
                View More Products
              </LinkButton>
            </span>
          </div>
        </div>
      </span>
    </Container>
  );
};

export default OurWork;
